body {
  margin: 0;
  padding: 0;
  font-family: FSAlbert, "Helvetica Neue", sans-serif;
  font-size: 12px;
  color: #555;
  background-color: #f3f3f3;
  border-collapse: collapse;
}

a {
  color: rgb(20, 121, 204);
  text-decoration: none;
  &:hover{
    color: rgb(0, 140, 255);
  }
}

table {
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-collapse: collapse;
  width: 100%;
  background-color: #fff;
  margin-bottom: 20px;
  box-sizing: border-box;

  th,
  td {
    padding: 10px 20px;
    text-align: left;

    &.buttons {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }
  }

  thead {
    th {
      border-bottom: 1px solid #ccc;
      background-color: #ddd;
      color: #444;
      font-weight: normal;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #ccc;
      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: #e3e3e3;
      }
    }
  }
}

.form {
  .horizontal-group {
    display: flex;
    gap: 20px;
    margin: 20px 0;

    .field {
      margin: 0;
    }
  }

  .field {
    margin: 20px 0;

    label {
      display: block;
      margin-bottom: 5px;
    }
  }
}
